/* src/App.css*/

.container {
  /* max-width: 100%; */
  padding: 0;
}

/* for AccessibilityWidget */

/* :root {
  --text-size: 16px;
  --underline-links: initial;
  --highlight-links: initial;
  --cursor-size: initial;
  --text-spacing: initial;
  --line-spacing: initial;
  --disable-animations: initial;
  --hide-images: initial;
  --highlight-labels: initial;
  --text-align: initial;
}

body,
p,
div,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: var(--text-size);
  filter: contrast(var(--contrast));
  letter-spacing: var(--text-spacing);
  line-height: var(--line-spacing);
  text-align: var(--text-align);

}

a {
  text-decoration: var(--underline-links);
  background-color: var(--highlight-links);
}


* {
  transition: var(--disable-animations);
  animation: var(--disable-animations);
}

img {
  display: var(--hide-images);
}

label {
  background-color: var(--highlight-labels);
} */

/* @media (max-width: 600px) {
  .carousel .thumbs-wrapper .thumbs .thumb {
    width: 40px;
    height: 40px;
  }
} */