/* src/components/NavBar/NavBar.css */

.navBar {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #ffffff;
  padding-top: 20px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

.navBar-icons-left,
.navBar-icons-right {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 15% 15%;
}

.navBar-icons-left img,
.navBar-icons-right img {
  /* height: 30px; */
  margin: 0 5px;
  opacity: 0.4;
  transition: opacity 0.3s ease;
  border: 1px solid #fff;
}

.navBar-icons-left img:hover,
.navBar-icons-right img:hover {
  opacity: 1;
  border: 1px solid #000;
}

.navBar-icons-left .icon-label,
.navBar-icons-right .icon-label {
  display: none;
  position: absolute;
  bottom: -20px;
  width: 100%;
  text-align: center;
  color: #000;
  font-size: 12px;
}

.navBar-icons-left-link {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.navBar-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
}

.navBar-links {
  display: flex;
  align-items: center;
  justify-content: center;
}

.navBar-item:hover {
  color: #ba0003;
  opacity: 1;
  /* border-bottom: 1px solid #000; */
}

.navBar-item {
  position: relative;
  color: #000;
  margin-left: 25px;
  opacity: 0.6;
  border-bottom: 1px solid transparent;
  padding: 10px;
  /* Add padding to enlarge the clickable area */
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  padding: 10px;
}

.dropdown-content a {
  display: block;
  color: #000;
  text-decoration: none;
}

.navBar-item.hovered .dropdown-content {
  display: block;
}

.dropdown-label {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 0.5rem;
  background-color: #f0f0f0;
  border-radius: 4px;
  color: #333;
  font-size: 0.8rem;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
}

/*  */
.language-dropdown {
  position: relative;
  display: inline-block;
  /* padding-top: 2.5rem; */
  color: #444;
  /* darker text color for better visibility */
  position: relative;
  /* Position the dropdown relative to the navbar */
  z-index: 9999;
  /* High z-index to ensure it is above everything else in the navbar */
}

.language-dropdown.open .language-icon {
  transform: rotate(180deg);
  color: #0070f3;
  /* highlight color when dropdown is open */
}

.language-icon {
  transition: transform 0.3s ease;
  cursor: pointer;
  /* change cursor to indicate clickable icon */
}

.language-icon:hover {
  opacity: 1;
  color: #0070f3;
  /* highlight color when hovering over icon */
}

.language-dropdown.open .language-menu {
  opacity: 1;
  pointer-events: all;
}

.language-menu {
  pointer-events: none;
  cursor: pointer;
  position: absolute;
  transform: translateX(-20%) translateY(+10%);
  padding: 0.5rem;
  background-color: #f6f6f6;
  /* lighter background for better contrast */
  border: 1px solid #ddd;
  /* subtle border around dropdown */
  border-radius: 4px;
  opacity: 0;
  transition: opacity 0.3s ease;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
  /* subtle shadow for depth */
}

.language-menu-items {
  display: flex;
  flex-direction: column;
  line-height: 1.6;
  /* more vertical spacing between items */
}

.language-menu-items li:hover {
  background-color: #eee;
  /* subtle highlight color when hovering over items */
}

/*  */
@media (max-width: 768px) {
  .logo-container {
    margin-left: 30px;
  }

  .navBar {
    display: grid;
    grid-template-areas: "navBar-icons-left navBar-center";
    justify-content: space-between;
    align-items: flex-start;
    background-color: #ffffff;
  }

  .navBar-center img {
    position: relative;
    left: 10%;
    width: 100%;
  }

  .navBar-item,
  .navBar-icons-left-link,
  .navBar-icons-right {
    display: none;
  }

  .side-menu {
    position: fixed;
    right: 0;
    transform: translateX(100%);
    height: 100vh;
    max-width: 300px;
    background-color: #f5f5f5;
    /* make background lighter */
    overflow-x: hidden;
    transition: transform 0.5s;
    padding: 1em;
    z-index: 1;
    box-shadow: -10px 0 20px rgba(0, 0, 0, 0.1);
    direction: inherit;
    border-radius: 20px;
    /* add border-radius */
  }

  .side-menu a {
    display: block;
    padding: 10px;
    text-decoration: none;
    font-size: 20px;
    color: #333;
    transition: color 0.3s ease-in-out;
  }

  .side-menu a:hover,
  .side-menu a:focus {
    color: #007bff;
    /* add blue color on hover and focus */
    background-color: #f0f0f0;
    /* add subtle background color on hover and focus */
  }

  .side-menu.open {
    transform: translateX(0);
  }
}

.navBar-mobile {
  display: inline;
  position: absolute;
  top: 0;
  right: 0;
  /* justify-content: flex-end; */
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
}

.navBar-mobile button {
  background: none;
  border: none;
  font-size: 2rem;
  padding: 10px;
  /* Add padding to enlarge the clickable area */
  /* cursor: pointer; */
  /* direction: ltr; */
}

@media screen and (min-width: 768px) {
  .navBar-mobile {
    display: none;
    /* Hide mobile menu button on large screens */
  }

  .side-menu {
    display: none;
    /* Hide side menu on large screens */
  }
}